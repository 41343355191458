import React from 'react';
import {Col, Container, Row} from "react-bootstrap";
import {mediaBaseURL} from "../../utils/constant";
import Link from "gatsby-link";
import HtmlParser from "react-html-parser";

function Episodesection({data}) {
    return (
        <div className="podcast_story p-100">
            <Container className="h-100">
                <Row className="h-100">
                    <Col xs={12} md={12} lg={8} xl={8} xxl={8} className="m-auto text-center">
                        <h5 className="episode_name">
                            {HtmlParser(data?.name)}
                        </h5>
                        <div className="iframe_section">
                            <div>{HtmlParser(data?.iframe_sec)}</div>
                        </div>
                        <div className="video_section">
                            <div>{HtmlParser(data?.video_sec)}</div>
                        </div>
                        <Row>
                            {data?.video_images?.map((value, i) => (
                                <Col xs={12} md={6} lg={4} xl={4} xxl={4} className="mb-4 text-center">
                                    <Link to={value.link} target="_blank">
                                        <img
                                            src={`${mediaBaseURL}${value.image}`}
                                            className="img-fluid"
                                            alt="image_podcast"/>
                                    </Link>
                                </Col>
                            ))}
                        </Row>
                    </Col>
                </Row>

            </Container>
        </div>
    );
}

export default Episodesection;